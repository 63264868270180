import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import '@/assets/scss/main.scss';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primeicons/primeicons.css';

import AppRouter from './AppRouter';
import { LoadingProvider } from './components/common/loader/LoadingContext';
import { CommonToast } from './components/common/CommonToast';
import { AuthProvider } from './components/auth/AuthContext';
import { CURRENT_USER_ID_KEY } from './shared/constants';


function App() {
  const navigate = useNavigate();
  const [currentUserId] = useState<number | null>(() => {
    const storedUser = localStorage.getItem(CURRENT_USER_ID_KEY);
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {      
      if (event.key === CURRENT_USER_ID_KEY) {
        const newUser = event.newValue ? JSON.parse(event.newValue) : null;
        console.log({newUser});
        
        if (!newUser || newUser.userId !== currentUserId) {
          navigate(0);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [currentUserId]);

  return (
    <AuthProvider>
      <LoadingProvider>
        <PrimeReactProvider value={{ unstyled: false }}>
          <CommonToast>
            <AppRouter />
          </CommonToast>
        </PrimeReactProvider>
      </LoadingProvider>
    </AuthProvider>
  );
}

export default App;
